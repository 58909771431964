import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You are using the Internet. Probably you have a good connection at home / office, right?`}</p>
    <p>{`🦄 Everything seems super fast while surfing the web. But, at some point you are on the metro:low 3G connection, low CPU power and low battery. Everything looks so much slower.`}</p>
    <p>{`🦄 Well, most sites that are big and bloated with useless code.`}</p>
    <p>{`Too much code that could be avoided when developing these sites. Usually, developers looks at the performance part of the site as last part of their process. It is not their fault most of the time but it is a sad reality.`}</p>
    <p>{`🦄 Enter the extension IMPORT COST.`}</p>
    <p>{`This extensions allows you to see the size of the imported module, while you are importing the entire library or just a particular utility.`}</p>
    <p>{`This is really helpful while developing to understand how big the bundle of your site will be when in production.`}</p>
    <p>{`🦄 Personally, I love this extension and I would recommend it to other developers!`}</p>
    <p>{`Let’s make sites smaller and faster!`}</p>
    <p>{`🦄 If you want to understand how this extension was built, you should read the post by the developers at WIX (the company behind this extension).`}</p>
    <p>{`The link of their blog post: `}<a parentName="p" {...{
        "href": "http://bit.ly/2jgPRPD"
      }}>{`http://bit.ly/2jgPRPD`}</a></p>
    <p>{`You can download the extension for Visual Studio Code here: `}<a parentName="p" {...{
        "href": "http://bit.ly/2HF9yzm"
      }}>{`http://bit.ly/2HF9yzm`}</a></p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      